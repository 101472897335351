import { Injectable } from '@angular/core';
import { AutocompleteEnumDto } from '@core/dto/auto-complete-enum-dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutocompleteDto } from '../dto/auto-complete-dto';

import { URL_CONFIG } from '../utils/url-configs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {

  constructor(private apiService: ApiService) { }
  
  public getForSelectFromObject(url: string, obj?: {}): Observable<any[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/${url}`, this.apiService.getQueryParamsForFilter(obj))
      .pipe(map(response => response.body.data));
  }

  public getForSelect(url: string, params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/${url}`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getFerramentaForSelect(params?: URLSearchParams): Observable<any> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/ferramenta`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getAreaForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/areas`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getNecessidadesEspeciaisForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/necessidades-especiais`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getEstadosForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/estados`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getGerenciasRegionaisForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/gerencias-regionais`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getMunicipioForSelect(estadoId: string, params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/municipios-by-estado/${estadoId}`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getMunicipioAllForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/municipios`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getDestinatarioForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/destinatario`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getAreasForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/areas`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getPerfisForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/perfil`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getPerfilForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/perfil`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getTipoServicoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/tipo-servico`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getTipoEducacaoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/tipo-educacao`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getMunicipiosForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/municipios`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getComponenteCurricularForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/componente-curricular-local`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getEixoComponenteCurricularForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/eixo-componente-curricular`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getSerieForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/serie`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getAreaFormacaoSuperiorForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/area-formacao-superior`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getOpcaoEducacaoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/opcao-educacao`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getMediacaoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/mediacao`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getAreaConhecimentoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/area-conhecimento`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getEstruturaCurricularForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/estrutura-curricular`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getEtapaForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/etapa`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getModalidadeForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/modalidade`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getEixoTecnologicoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/eixo-tecnologico`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getMatrizCurricularVersaoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/matriz-curricular/versao`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getTurnoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/turno`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getLogEntidadeForSelect(params?: URLSearchParams): Observable<AutocompleteEnumDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/log-entidade`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteEnumDto(data))));
  }

  public getLogUsuarioForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/log-usuario`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getFormacaoAcademicaForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/multiselect/formacao-academica`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getCursoFormacaoSuperiorForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/curso-formacao-superior`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getForSelectFromObjectDtoCustom(url: string, obj?: {}): Observable<any[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/${url}`, this.apiService.getQueryParamsForFilter(obj))
      .pipe(map(response => response.body.data));
  }

  public getPerfilAlunoForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/perfil-usuario`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getTipoDeficienciaSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/tipos-deficiencias`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getPaisesSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/paises`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getRecursoAvaliacaoSeabSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/recursos-avaliacao-seab`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getComponenteCurricularBnccForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/componente-curricular-bncc`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getModalidadesByEtapa(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/curriculo-modalidade-by-list-etapas`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getCurriculoEstruturaComponente(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/curriculo-estrutura-componente`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getCurriculoEtapa(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/curriculo-etapa`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }
}
