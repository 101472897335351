
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';

export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((httpErrorResponse) => {
                    if (httpErrorResponse.error instanceof ErrorEvent) {
                        // Este é um erro do lado do cliente.
                      if (httpErrorResponse.status !== 401) {
                        SweetalertCustom.showAlertConfirm(`Falha na operação.`, { type: 'error' }, 'Ok', 'Contate o administrador do sistema.');
                      }
                    } else {
                        // Este é um erro do lado do servidor.
                        if (httpErrorResponse.error?.errors) {
                            SweetalertCustom.showAlertConfirm('Atenção!', { type: 'error' }, 'OK', httpErrorResponse.error.errors);
                        } else {
                            SweetalertCustom.showAlertConfirm(`Falha na operação.`, { type: 'error' });
                        }
                    }
                    return throwError(httpErrorResponse.error);
                })
            );
    }
}
