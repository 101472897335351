import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import { Route, Routes } from '@angular/router';
import { ExclusaoRegistroDto } from '@core/dto/ExclusaoRegistro/exclusao-registro-dto';
import { ArquivoService } from '@core/services/arquivo.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ErrorMessage } from 'src/app/core/models/error-message.model';
import { environment } from 'src/environments/environment';
import { SweetalertCustom } from './sweetalert-custom';
import {isArray} from 'rxjs/internal-compatibility';
import {FilterStateService} from '@core/services/shared/filter-state.service';
import { TableCrudMapDto } from '@core/dto/ExclusaoRegistro/table-crud-map.dto';

const NAME_PROJECT = environment.namespaceProject;

export class Util {
  /**
   * Função para setar os errors da requisição conforme de acordo com os seus tipos
   * @param errorMessage Objeto utilizado para incluir os erros
   * @param error Objeto que vem o HttpErroResponse
   * @param typeErr String para setar qual o tipo de error ex: danger, success, warning, info
   * @param errorCustom Boolean para caso queira inserir uma mensagem sem ser pelo tipo do erro da requisição
   */
  static setErrorMessage(
    errorMessage: ErrorMessage,
    error,
    typeErr = 'danger',
    errorCustom = false
  ) {
    if (errorCustom) {
      errorMessage.errorType = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 400) {
      errorMessage.errorType = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 403) {
      errorMessage.errorType = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push('Usuário não tem permissão de acesso!');
    }

    if (!errorCustom && error.status === 0 || error.status === 404) {
      errorMessage.errorType = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push('Oopss ocorreu um erro ao processar o seu pedido, não conseguimos conectar com os nossos serviços, por favor tente novamente!');
    }

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, 10000);
  }

  static setErrorMessageCustom(errorMessage: ErrorMessage, error: any, typeErr = 'danger', timeoutClearMessage = 30000) {

    errorMessage.errorType = typeErr;
    errorMessage.existError = true;
    errorMessage.errorList = this.setErrorListCustom(error);

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, timeoutClearMessage);
  }

  /**
   * Função para Limpar Objeto utilizado para incluir os erros
   * @param errorMessage Objeto utilizado para incluir os erros
   */
  static clearErrorMessage(errorMessage: ErrorMessage) {
    errorMessage.errorType = '';
    errorMessage.existError = false;
    errorMessage.errorList = [];
  }

  /**
   * Função utilizada para setar os errors na lista de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorList(error) {
    let list = [];
    Array.isArray(error.error.errors) ? list = error.error.errors : list.push(error.error.errors);
    return list;
  }

  /**
   * Função utilizada para setar os errors na lista custumizada de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorListCustom(error) {
    let list = [];
    Array.isArray(error) ? list = error : list.push(error);
    return list;
  }

  /**
   * Função para alterar o valor do botão de Buscar
   * @param buttonSubmitConfig Objeto para configuração do botão
   * @param isResp Controle a submissão do botão
   */
  static setBtnFilterReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Buscando' : 'Buscar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Salvando' : 'Salvar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
* @param fieldNames array com o nome dos formControls do formulário
* @param form formulário a ser percorrido e ter seus campos resetadosa
*/
  static resetarCampos(fieldNames: string[], form: FormGroup) {
    const fields = fieldNames.map(fieldName => form.get(fieldName));

    for (const field of fields) {
      field.reset();
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReqCustom(buttonSubmitConfig?, isResp = false, desc1 = '', desc2 = '') {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? desc2 : desc1;
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }
  /**
   * Metodo para pegar o nome para a tela (cadastrar/editar)
   * @param id Identificador
   * @param detalhes Bolean para informar se a tela é de detalhes
   */
  static getScreenName(id?: string, detalhes = false) {
    return (!id || !id.trim()) ? 'ADICIONAR' : (id && !detalhes) ? 'EDITAR' : 'VISUALIZAR';
  }

  /**
   * Função para setar a classe de errro no campo
   * @param formGroup FormGroup do parametro
   * @param messageDisplay Mensagem a ser exibida
   * @param field Campo que receberá a mensagem de validação
   */
  static setErrorsValidate(formGroup: FormGroup, messageDisplay, field: string) {
    if (messageDisplay[field] || Util.isRequired(field, formGroup) && !formGroup.get(field)?.value && formGroup.get(field).touched) {
      return 'is-invalid';
    }
  }

  static isRequired(controlName: string, formGroup: FormGroup) {
    const control = formGroup.get(controlName);
    return control?.validator && control.validator({} as AbstractControl) &&  control.validator({} as AbstractControl)?.hasOwnProperty('required');
  }

  /**
   * Função para preencher os valores pelo ID
   * @param resp variavel que vem os dados da requisição
   * @param formGroup variavel que traz o form group
   */
  static patchValueForm(obj: any, formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const value = obj[key];
      if ((value) || (typeof value === "boolean")) {
        formGroup.controls[key].patchValue(value);
      }
    });
  }

  /**
   * Adiciona na URL o parametro de "Ativo" com o valor "true"
   */
  static createFilterStatusActive() {
    const params: URLSearchParams = new URLSearchParams();
    params.append('ativo', 'true');

    return params;
  }

  /**
   * Retorna as URL Search Params utilizadas nas requisições
   */
  static createFilter() {
    const params: URLSearchParams = new URLSearchParams();
    return params;
  }

  /**
   * Normaliza a string Removendo os acentos da string
   * @param str string para ser removida os acentos
   */
  static normalizeString(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Limpa todos os campo do form
   * @param formGroup O form a ser limpo os campos
   */
  static clearFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
      const control = formGroup.get(campo);
      if (control instanceof FormGroup || control instanceof FormControl) {
        if (control instanceof FormGroup) {
          this.clearFields(control);
        } else {
          if (Array.isArray(control.value)) {
            control.setValue([]);
          } else {
            control.setValue('');
          }
        }
      } else if (control instanceof FormArray) {
        control.controls.splice(0);
        control.updateValueAndValidity();
      }
    });
    formGroup.updateValueAndValidity();
  }

  /**
   * Cria as query params do filtro de busca
   * @param param Objero a ser convertido em parametros
   */
  static getQueryParams(param: object) {
    const params: URLSearchParams = Util.createFilter();

    Object.keys(param).forEach(campo => {
      const item = param[campo];
      if (item) {
        params.append(campo, item);
      }
    });

    return params;
  }

  /**
   * Obtem os campos invalidos do form
   * @param form Form a ser verificado
   */
  static catchFieldsInvalids(form: FormGroup | FormArray): string[] {
    const invalidControls: string[] = [];

    const recursiveFunc = (formGP: FormGroup | FormArray) => {
      Object.keys(formGP.controls).forEach(field => {
        const control = formGP.get(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        } else {
          if (control.invalid) { invalidControls.push(field); }
        }
      });
    };
    recursiveFunc(form);
    return invalidControls;
  }

  /**
   * * Abre o modal no tamanho
   * @param modalService Serviço do modal
   * @param component O componente a ser aberto
   * @param size O tamanho do modal (lg, xl, sm) / Valor defaul md
   * @param options Obj com a option do NgbModalOption desejada
   */
  static openModal(
    modalService: NgbModal,
    component: any,
    size: string = 'md',
    options: NgbModalOptions = {}
  ) {
    const modalRef = modalService.open(component, {
      backdrop: 'static',
      size,
      keyboard: false,
      windowClass: 'modal-custom-' + size,
      ...options,
    });

    return modalRef;
  }

  /**
   * Convert o arquivo de base64 em BlobData
   * @param base64Data Arquivo em Base64
   * @param contentType Tipo do content type
   * @param sliceSize Tamanhp do arquivo
   */
  static convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Realiza o download do arquivo
   * @param file Arquivo para ser baixado
   */
  static downloadFile(file: any) {
    const blobData = Util.convertBase64ToBlobData(file.base64);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobData, file.filename);
    } else { // chrome
      const blob = new Blob([blobData], { type: file.extension });
      const url = URL.createObjectURL(blob);
      const ext = file.extension.split('/').length > 0 ? file.extension.split('/')[1] : '';
      if (ext === 'pdf' || ext === 'PDF' ||
        ext === 'png' || ext === 'PNG' ||
        ext === 'jpg' || ext === 'JPG' ||
        ext === 'jpeg' || ext === 'JPEG') {
        window.open(url, '_blank');
        return;
      }

      const link = document.createElement('a');
      link.href = url;
      link.download = file.filename;
      link.click();
    }
  }

  static distinct(myArr, prop): any[] {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj =>
        mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  static getUsuarioSession() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.usuario`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static getperfilDescriptionSession() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.perfil`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static getCurrentUtbEscola() {
    const utb = localStorage.getItem(`${NAME_PROJECT}.utbEscola`);
    return utb ? JSON.parse(atob(utb)) : null;
  }

  static getListYear(): any[] {
    const yearToday = new Date().getFullYear();
    const range = [];
    range.push(yearToday);
    for (let i = 1; i < 12; i++) {
      range.push(yearToday - i);
    }
    return range;
  }

  static getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
  // 2022-03-16T17:39:34.185889
  static formatDateHours(date?: string): string {
    if (date) {
      const ano = date.substring(0, 4);
      const mes = date.substring(5, 7);
      const dia = date.substring(8, 10);

      const newDate = new Date(date);

      const horas = newDate.getHours();
      const minutos = newDate.getMinutes();

      return dia + '/' + mes + '/' + ano + ' às ' + Util.formatNumberDate(horas) + ':' + Util.formatNumberDate(minutos);
    }
  }
  static formatNumberDate(numberDate: number) {
    return numberDate < 10 ? ('0' + numberDate) : numberDate;
  }

  /**
   * Função que retorna um string com o formato 'YYYY-MM-DD'
   * @param date data a ser convertida para o formato do form
   */

  static formatDateToInputFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  /**
   * Função que converte uma string com o formato 'YYYY-MM-DD' em uma data
   * @param date data em string a ser convertida para o formato de data
   */

  static formatStringToDate(date: string) {
    return date && new Date(
      Number(date.slice(0, 4)),
      Number(date.slice(5, 7)) - 1,
      Number(date.slice(8, 10))
    );
  }


  static getAnos(): Array<any> {
    const anosList = [];

    for (let ano = 2012; ano <= 2012 + 11; ano++) {
      anosList.push({
        name: ano,
        value: ano,
      });
    }

    return anosList;
  }

  static getHorarios(): Array<any> {
    const horariosList = [];

    for (let hora = 0; hora < 24; hora++) {
      for (let minutos = 0; minutos < 56; minutos += 5) {
        const data = moment();
        data.hour(hora);
        data.minute(minutos);

        horariosList.push({
          name: data.format('HH:mm'),
          value: data.format('HH:mm'),
        });
      }
    }
    return horariosList;
  }

  /**
   * Verifica se há algum campo preenchido no objeto ou array
   * @param object objeto a ser verificado
   * @returns true caso haja algum valor preenchido, senão false
   */
  static objectHasAnyValue(object: any) {
    return Object.keys(object).some(key => {
      const value = object[key];
      return typeof value === 'boolean' ||
        typeof value === 'number' ||
        (Array.isArray(value) ? value.length > 0 : value) ||
        value?.trim();
    });
  }


  static transformAutocompletes(object: any): any {
    const entity = {};
    Object.keys(object).forEach(key => {
      const value = object[key];
      if (typeof value != 'boolean' && value === '') return; // Não envia nos casos de não ser selecionado enum ou multiselect
      if (typeof value == 'object' && !Array.isArray(value)) {
        entity[key] = value?.id
      } else if (Array.isArray(value)) {
        entity[key] = value.map(valueChild => valueChild.id || valueChild.value)
      } else {
        entity[key] = value;
      }
    })
    return entity;
  }

  /**
   * Ordena os cards de submenu por ordem alfabética
   * @param rotas lista de rotas para ser ordenada
   */
  static sortRoutes(rotas: Routes) {
    return rotas.sort((a: Route, b: Route) => {
      if (a.path > b.path) {
        return 1;
      } else if (a.path < b.path) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  /**
   * Exibe mensagem genérica para um item que já exista em uma listagem
   * @param nomeExibicao nome do campo que será exibido. Ex: Equipamento. Ex2: Quadro Funcional
   */
  static showMessageItemOnTheList(nomeExibicao: string): void {
    SweetalertCustom.showAlertConfirm(
      'Atenção',
      { type: 'warning' },
      'Ok',
      `${nomeExibicao} já existe na lista.`
    );
  }
  /**
   * Exibe mensagem genérica para um item que já exista em uma listagem
   * @param nomeExibicao nome do campo que será exibido. Ex: Equipamento. Ex2: Quadro Funcional
   */
  static showMessage(): void {
    SweetalertCustom.showAlertConfirm(
      'Atenção!',
      { type: 'warning' },
      'Ok',
      `Este registro já existe na relação proposta!`
    );
  }

  /**
   * Valida se existe valor preenchido na carga horária do componente curricular
   * e retorna TRUE caso não exista valor informado.
   * @param stepMatriz Array de componente curricular local
   */
  static cargaHorariaInvalida(stepMatriz: any): boolean {
    let invalido = false;
    stepMatriz.controls.forEach(step => {
      step.get('cargasHorarias').controls.forEach(carga => {
        if (!(carga.get('aulasSemanais').value.toString().length > 0)) {
          invalido = true;
          //carga.aulasSemanais.invalid = true;
          const control = carga.get('aulasSemanais') as FormControl;
          control.setErrors({
            cargaHorarariaInvalid: true
          });
        }
      });
    });

    if (invalido) {
      SweetalertCustom.showAlertConfirm(`Atenção`, { type: 'warning' }, 'Ok', 'A matriz não pode ter carga horária vazia.<br>Informe um valor para a carga horária.');
    }

    return invalido;
  }

  /**
   * Valida se existe valor preenchido na carga horária do componente curricular
   * e retorna TRUE caso não exista valor informado.
   * @param stepMatriz Array de componente curricular local
   */
  static cargaHorariaInvalidaMatrizBncc(stepMatriz: any): boolean {
    let invalido = false;
    stepMatriz.controls.forEach(step => {
      step.get('cargasHorarias').controls.forEach(carga => {
        if (!(carga.get('cargaHoraria').value.toString().length > 0)) {
          invalido = true;
          const control = carga.get('cargaHoraria') as FormControl;
          control.setErrors({
            cargaHorarariaInvalid: true
          });
        }
      });
    });

    if (invalido) {
      SweetalertCustom.showAlertConfirm(
        `Atenção`,
        { type: 'warning' },
        'Ok',
        'Todos os campos obrigatórios precisam ser preenchidos corretamente.'
      );
    }

    return invalido;
  }

  /**
   * Valida se existe valor preenchido na carga horária do componente curricular
   * e retorna TRUE caso não exista valor informado.
   * @param stepMatriz Array de componente curricular local
   */
  static cargaHorariaInvalidaMatrizBnccSemestral(stepMatriz: any): boolean {
    let invalido = false;
    stepMatriz.controls.forEach(step => {
      step.get('cargasHorarias').controls.forEach(carga => {
        if (!(carga.get('semestre1CargaHoraria').value.toString().length > 0)) {
          invalido = true;
          const control = carga.get('semestre1CargaHoraria') as FormControl;
          control.setErrors({
            cargaHorarariaInvalid: true
          });
        }
        if (!(carga.get('semestre2CargaHoraria').value.toString().length > 0)) {
          invalido = true;
          const control = carga.get('semestre2CargaHoraria') as FormControl;
          control.setErrors({
            cargaHorarariaInvalid: true
          });
        }
      });
    });

    if (invalido) {
      SweetalertCustom.showAlertConfirm(
        `Atenção`,
        { type: 'warning' },
        'Ok',
        'Todos os campos obrigatórios precisam ser preenchidos corretamente.'
      );
    }

    return invalido;
  }


  // Permite digitar num campo input text apenas caracteres alfanúmericos
  static keyPressAlphaNumeric(event) {
    const inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z\u00C0-\u00FF0-9 ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  static onBlurAlphaNumericOnly(string) {
    return string.replaceAll(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, '');
  }
  static validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      const wasDisabled = control.disabled;
      if (wasDisabled) {
        control.enable({ emitEvent: false });
      }

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.validateAllFormFields(control);
      }

      if (wasDisabled) {
        control.disable({ emitEvent: false });
      }
    });
    return formGroup.valid;
  }

  static isUserGRE(): boolean {
    const usuario = this.getUsuarioSession();
    if (usuario) {
      return usuario.greId !== null;
    }
  }

  static isUserADM(): boolean {
    const usuario = this.getUsuarioSession();
    if (usuario) {
      return usuario.greId == null;
    }
  }

  static compareDateAndMonth(date1Str: string, date2Str: string) {
    const date1 = new Date(date1Str);
    const date2 = new Date(date2Str);

    return moment(date1).format('DD/MM') === moment(date2).format('DD/MM');
  }

  /**
   * Formata uma data para o formato "YYYY-MM-DDTHH:mm:ss.000Z"
   */
  static formatDate(date: Date | string) {
    return new Date(date).toISOString();
  }

  static validarData(dataString: string): boolean {
    const data = new Date(dataString);
    return !isNaN(data.getTime()) && data.toISOString() === dataString;
  }

  static montarParametrosDeVerificarRelacionamento(id: string, schemaNome: string, tabelaNome): URLSearchParams {
    const params: URLSearchParams = Util.createFilter();
    params.append('schemaNome', schemaNome);
    params.append('tabelaNome', tabelaNome);
    params.append('id', id);

    return params;
  }

  static exibirTabelasRelacionadas(item: any) {
    SweetalertCustom.ShowAlertConfirmExclusaoByRelacionamento(
      item.tabelasRelacionadas
    );
  }

  static setarTabelasRelacionadas(item: any, tabelasRelacionadas: Array<ExclusaoRegistroDto>) {
    item.tabelasRelacionadas = tabelasRelacionadas;
  }

  static downloadFromBack(arquivo: any, arquivoService: ArquivoService): void {
    arquivoService.download(arquivo.id.toString()).subscribe(res => {
      if (res.body.base64) {
        this.downloadPdfFile(res.body.base64, arquivo.nomeOriginal || arquivo.nome);
        return;
      }

      this.downloadFileByFileStream(res.body, arquivo.nome);
    }, (err) => { });
  }

  static downloadPdfFile(base64: string, fileName: string): void {
    const downlodedFile = Util.convertBase64ToBlobData(base64, 'application/pdf');
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  static downloadFileByFileStream(base64: any, fileName: string): void {
    const downlodedFile = new Blob([base64], { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  static getDadosEscolaLotacaoAee() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.escolaLotacaoAee`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static mudarValidacaoPorCampos(fields: string[], validators: any[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).setValidators(validators);
      form.get(field).updateValueAndValidity();
    });
  }

  static limparValidacaoPorCampos(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).clearValidators();
      form.get(field).updateValueAndValidity();
    });
  }

  static desabilitarCampos(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).disable();
    });
  }

  static habilitarCampos(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).enable();
    });
  }

  static formLimparValores(formControlsNames: string[], form: FormGroup): void {
    formControlsNames.forEach((formControlName: string) => {
      form.get(formControlName).setValue('');
    });
  }

  static getValidateMessages() {
    return {
      login: {
        required: 'Login é obrigatório.',
        cpfInvalid: 'CPF é inválido.'
      },

      password: {
        required: 'Senha é obrigatória.',
      },

      senhaAtual: {
        required: 'A Senha Atual é obrigatória.'
      },

      novaSenha: {
        required: 'Senha é obrigatória.',
        passwordNotMatch: 'Senhas não se correspondem.',
        passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
      },

      novaSenhaRepeat: {
        required: 'Confirmar Senha é obrigatória.',
        passwordNotMatch: 'Senhas não se correspondem.',
        passwordInvalid: 'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.'
      },

      name: {
        required: 'Nome é obrigatório.',
      },

      nome: {
        required: 'Nome é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.',
        invalidName: 'Inválido, há espaços extra, duplicidades ou caracteres invisíveis.',
        nomeInvalidoMatriz: 'Inválido, há espaços extra, duplicidades ou caracteres invisíveis.'
      },

      sobrenome: {
        required: 'Sobrenome é obrigatório.',
      },

      cpf: {
        required: 'CPF é obrigatório.',
        cpfInvalid: 'CPF inválido.',
      },

      descricao: {
        required: 'Descrição é obrigatória.',
        maxLength: 'É permitido apenas 200 caracteres.'
      },

      sigla: {
        required: 'Sigla é obrigatória.',
        maxLength: 'É permitido apenas 5 caracteres.'
      },

      email: {
        required: 'E-mail é obrigatório.',
        emailInvalid: 'E-mail é inválido.'
      },

      sobreNome: {
        required: 'Sobrenome é obrigatório.',
      },

      telefone: {
        required: 'Telefone é obrigatório.',
        phoneInvalid: 'Telefone é inválido.'
      },

      perfilId: {
        required: 'Perfil é obrigatório.',
      },

      perfils: {
        required: 'Perfil é obrigatório.',
      },

      municipioId: {
        required: 'Município é obrigatório.',
      },

      estadoId: {
        required: 'Estado é obrigatório.',
      },

      departamentoId: {
        required: 'Departamento é obrigatório.',
      },

      codigo: {
        required: 'Código é obrigatório.',
        maxLength: 'É permitido apenas 9 caracteres.'
      },

      codigoInep: {
        required: 'O Código INEP é obrigatório.',
        maxLength: 'É permitido apenas 5 caracteres.',
      },

      codigoUtb: {
        required: 'O Código UTB é obrigatório.',
        maxLength: 'É permitido apenas 5 caracteres.',
      },

      areaCursoProfissionalId: {
        required: 'Área é obrigatório.',
      },

      subareaId: {
        required: 'Subárea é obrigatório.',
      },

      link: {
        required: 'Link é obrigatório.',
      },

      anexo: {
        required: 'Anexo é obrigatório.',
      },

      ferramentas: {
        required: 'Ferramentas é obrigatório.',
      },

      tipoVeiculo: {
        required: 'Tipo de veiculo é obrigatório.',
      },

      restricaoId: {
        required: 'Restrição é obrigatória.'
      },

      tipoServicoId: {
        required: 'Tipo de Serviço é obrigatório.',
      },

      dataInicio: {
        required: 'A data de início é obrigatória.',
      },

      dataFim: {
        required: 'A data de fim é obrigatória.',
      },

      linkUrl: {
        required: 'O link é obrigatório.',
      },

      camposAdicionais: {
        required: 'Campos adicionais é obrigatório.',
      },
      nomeQuestionario: {
        required: 'O nome do questionário é obrigatório.',
      },
      nomeSecao: {
        required: 'Nome da seção é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.'
      },
      posicaoSecao: {
        required: 'A posição  da seção é obrigatória.'
      },
      gerenciaRegionalId: {
        required: 'A gerência regional é obrigatória.'
      },
      perfilAlvoQuestionarioId: {
        required: 'O perfil alvo do questionário é obrigatório.'
      },
      periodicidade: {
        required: 'A periodicidade é obrigatória.'
      },
      intrucoes: {
        required: 'As instruções são obrigatórias.',
        maxLength: 'É permitido apenas 200 caracteres.'
      },
      eixoComponenteCurricularId: {
        required: 'Eixo do componente curricular é obrigatório.'
      },

      serieId: {
        required: 'Série é obrigatório.'
      },

      bimestre: {
        required: 'Bimestre é obrigatório.'
      },

      ano: {
        required: 'Ano é obrigatório.'
      },

      componenteCurricularId: {
        required: 'Componente Curricular é obrigatório.'
      },

      areaFormacaoSuperior: {
        required: 'Área de formação superior é obrigatório.'
      },

      tiposEducacaoId: {
        required: 'Tipo de educação é obrigatorio.'
      },

      modeloDiarioClasse: {
        required: 'Modelo diário de classe é obrigatorio.'
      },

      grau: {
        required: 'Grau é obrigatório.'
      },
      dependenciaAdministrativa: {
        required: 'Dependência Administrativa é obrigatória.'
      },

      tipoInstuicao: {
        required: 'Tipo de instituição é obrigatório.'
      },

      diaLetivo: {
        required: 'O campo "É dia letivo?" é obrigatório.'
      },

      opcoesEducacao: {
        required: 'É necessário selecionar ao menos uma opção de educação.'
      },

      areasConhecimentos: {
        required: 'É necessário selecionar ao menos uma área de conhecimento.'
      },

      componentesCurriculares: {
        required: 'É necessário selecionar ao menos um componente curricular.'
      },

      eixo: {
        required: 'O Eixo Tecnológico é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.'
      },

      eixoTecnologicoId: {
        required: 'O Eixo Tecnológico é obrigatório.',
      },

      tipoDestinacaoLixo: {
        required: 'O Tipo de Destinação do Lixo é obrigatório.',
        maxLength: 'É permitido apenas 100 caracteres.'
      },

      obrigacoesLeis: {
        required: 'As Observações e Leis são obrigatórias.',
        maxLength: 'É permitido apenas 5000 caracteres.',
      },

      docente: {
        required: 'O Docente é obrigatório.',
      },

      itinerarioFormativoId: {
        required: 'O Itinerário Formativo é obrigatório.',
      },

      direitoAprendizagem: {
        required: 'Os Direitos de Aprendizagem é obrigatório.',
      },
      tipoMaterialId: {
        required: 'O Tipo de material é obrigatório.'
      },
      tipoProgramaId: {
        required: 'O Tipo de programa é obrigatório.'
      },
      referenciaMaterialId: {
        required: 'A Referência é obrigatória.'
      },
      necessidadesEspeciaisId: {
        required: 'A Necessidade especial é obrigatória.'
      },
      tipo: {
        required: 'Tipo é obrigatório.'
      },
      dia: {
        required: 'Dia é obrigatório.'
      },
      mes: {
        required: 'Mês é obrigatório.'
      }
    };
  }

  static sortArrayByNome(model: any[]) {
    if (isArray(model) && model.length > 0) {
      model.sort((a, b) => {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        return 0;
      });
    }
    return model;
  }

  static arraysIguaisPorId(array1: { id: string; nome: string }[], array2: { id: string; nome: string }[]): boolean {

    if (array1.length !== array2.length) {
      return false;
    }

    // Ordena os arrays por `id` para garantir a comparação correta
    array1.sort((a, b) => a.id.localeCompare(b.id));
    array2.sort((a, b) => a.id.localeCompare(b.id));

    // Verifica se todos os `id`s dos dois arrays são iguais
    return array1.every((item, index) => item.id === array2[index].id);
  }

  static agruparComponentesCurricularesItinerarioFormativo(dados: any) {
    // Array para armazenar os agrupamentos com a chave 'componenteCurricular'
    const agrupado = [];

    dados.forEach(item => {
      const componenteCurricularId = item.curriculoComponenteCurricularId.id;

      // Verificar se já existe um componente curricular com essa chave
      let componenteExistente = agrupado.find(componente =>
        componente.componenteCurricular.id === componenteCurricularId
      );

      // Se não existir, cria o objeto componente curricular
      if (!componenteExistente) {
        componenteExistente = {
          componenteCurricular: {
            id: componenteCurricularId,
            descricao: item.curriculoComponenteCurricularId.descricao,
            nome: item.curriculoComponenteCurricularId.nome,
            anoSerieCiclos: []
          }
        };
        agrupado.push(componenteExistente);
      }

      // Adiciona o curriculoAnoSerieCicloId e outros campos associados ao array 'anoSerieCiclos'
      componenteExistente.componenteCurricular.anoSerieCiclos.push({
        id: item.curriculoAnoSerieCicloId.id,
        descricao: item.curriculoAnoSerieCicloId.descricao,
        nome: item.curriculoAnoSerieCicloId.nome,
        carga: item.carga,
        semestre1Carga: item.semestre1Carga,
        semestre2Carga: item.semestre2Carga,
        nomeModulo: item.nomeModulo,
        modulo: item.modulo
      });
      componenteExistente.componenteCurricular.anoSerieCiclos.sort((a, b) => a.nome.localeCompare(b.nome));
    });

    return agrupado;
  }

  static patchSavedFilter(filterStateService: FilterStateService, crudKey: string, form: FormGroup): void {
    const savedFilters = filterStateService.getFilter(crudKey);
    if (savedFilters) {
      form.patchValue(savedFilters);
    }
  }

  static formatTableCrud(usedTable: ExclusaoRegistroDto[], tableCrudMap: TableCrudMapDto[]): ExclusaoRegistroDto[] {
    let tableReturn = [];
    usedTable.forEach(element => {
      tableReturn.push(tableCrudMap.filter(x => x.table === element.foreignSchemaTable).map(x => x.crud));
    });
  
    let tableFormated = tableReturn.reduce((acc, val) => acc.concat(val), []);
    tableFormated = [...new Set(tableFormated )];
  
    return tableFormated.map((x: string) => new ExclusaoRegistroDto({ foreignSchemaTable: x }));
  }

  static filterTablesIgnoreEmUso(usedTable: ExclusaoRegistroDto[], tablesIgnoreEmUso: string[]): ExclusaoRegistroDto[] {
    return usedTable.filter(x => !tablesIgnoreEmUso.includes(x.foreignSchemaTable));
  }

}
