import { Component, Input } from '@angular/core';
import { ErrorMessage } from 'src/app/core/models/error-message.model';
import { Util } from 'src/app/shared/utils/util';

@Component({
  selector: 'app-alert-custom',
  templateUrl: './alert-custom.component.html',
  styleUrls: ['./alert-custom.component.scss']
})
export class AlertCustomComponent {

  @Input() error = new ErrorMessage();
  @Input() fontBigger: boolean;

  closeAlert() {
    Util.clearErrorMessage(this.error);
  }

  typeError() {
    switch (this.error.errorType) {
      case 'danger':
        return 'alert-danger';
      case 'success':
        return 'alert-success';
      case 'info':
        return 'alert-info';
      case 'warning':
        return 'alert-warning';
      default:
        return 'alert-dark';
    }
  }

  setFontSize() {
    if (this.fontBigger) {
      return 'bigger';
    }
    return '';
  }

}
